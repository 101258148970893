.edit-submit {
    margin: 8px 0;
    float: left;
    color: rgb(3, 169, 244) !important;

    &:disabled {
        color: rgba(0, 0, 0, 0.38) !important;
    }
}

.edit-profile-picture {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .profilepicture {
        border-radius: 50%;
        object-fit: cover;
    }

    .edit-profile-picture-info {
        display: flex;
        flex-direction: column;
        margin-left: 24px;

        span {
            font-size: 24px;
        }

        .forgot-password-link {
            outline: none;
            border: none;
            background-color: transparent;
            font-size: 14px;
            color:  rgb(3, 169, 244);
            font-weight: bold;
            cursor: pointer;
            text-decoration: none;
    
            &:hover {
                opacity: 0.8;
            }
        }
    }

}