.paper {
    max-width: 700px;
    width: calc(100% - 16px);
    margin: 8px;
    padding: 16px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #e0e0e0;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;

        .header-title {
            display: flex;
            align-items: center;
            overflow: hidden;
            word-break: break-word;

            .icon {
                display: flex;
                margin-right: 8px;
                align-items: center;
                justify-content: center;
            }

            .title {
                font-size: 24px;
            }
        }

        .widget-actions {
            transition: opacity .2s linear;
        }
    }

    .widget-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .children {
            width: 100%;
            margin-right: 8px;
        }

        .reorder {
            margin: 10px;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;

            &:active,
            &:focus {
                cursor: grabbing;
                cursor: -moz-grabbing;
                cursor: -webkit-grabbing;
            }
        }
    }
}