@import '../../variables.scss';

.sidenav {
    height: 100%;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    background: #fff;
    overflow-x: hidden;
    padding-top: 64px;
    transition: 0.5s;
    margin-top: 8px;

    .nav-item,
    .nav-item .selected {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: left;
        margin: 0 8px;
        padding: 8px 8px 8px 16px;
        text-decoration: none;
        color: #101010;
        transition: 0.2s;
        border-radius: 32px;

        &:hover {
            background: #f1f3f4;
        }

        div {
            margin-left: 8px;
            transition: opacity 0.2s;
        }
    }

    .selected, .selected:hover {
        background: rgba($fizlink-blue, 0.5);
    }
}

.nav-content {
    display: block;
    height: 100%;
    margin-bottom: 8px;
    background: #fff;
    overflow: hidden;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
}