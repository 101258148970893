@import '../../variables.scss';

.support-tab {
    text-decoration: none;
    cursor: pointer;
    z-index: 998;
    padding: 8px 0;
    border-radius: 4px 0 0 4px;
    color: rgba(0, 0, 0, 0.54);
    background-color: $fizlink-yellow;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    min-height: 60px;
    writing-mode: vertical-lr;
    right: 0px;
    transform: translate(4px, -50%);

    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    .support-icon {
        transform: rotate(90deg);
        margin-bottom: 4px;
    }

    .support-text {
        padding: 8px;
    }
}