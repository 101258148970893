.link-preview {
    width: 100%;
    height: calc(100% - 48px);
    margin: 0 auto;
    overflow: hidden;
    padding: 16px;

    .preview {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 722px;
        height: 100%;
        width: 100%;

        .preview-body {
            max-width: 100%;
            font-size: 24px;
            position: relative;
            overflow-y: scroll;
            overflow-x: hidden;
            flex-grow: 1;
            -webkit-flex-grow: 1;

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            .mobile-identity {
                font-size: 18px;

                .profilepicture {
                    height: 72px !important;
                    width: 72px !important;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .links {
                font-size: 12px;

                img {
                    height: 64px;
                    width: 64px;
                    object-fit: cover;
                }
            }
        }

        .preview-footer {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-grow: 1;
            -webkit-flex-grow: 1;
            padding: 16px;
            width: 100%;
            background-color: white;
        }
    }
}