@import '~react-md/src/scss/react-md';

.redirecturl-input {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: arial, helvetica, sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    .md-text--disabled {
        color: rgba(0, 0, 0, 0.7) !important;
    }

    &::before {
        content: attr(data-domain);
        color: rgba(0, 0, 0, 0.54);
    }
}

.selector-footer {
    display: flex;
    align-items: center;
    padding-top: 8px;

    i {
        margin-left: 8px;
        font-size: 16px !important;
        cursor: default;
    }

}

.selector-clicks {
    display: flex;
    align-items: center;
    
    span {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.54);
        cursor: default;
    }

    i {
        margin-left: 0;
        margin-right: 4px;
        font-size: 16px !important;
        color: rgba(0, 0, 0, 0.54);
    }
}