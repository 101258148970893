.loyalty {
    position: absolute;
    width: calc(100% - 32px);
    height: calc(100% - 96px);
    top: 64px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 16px auto;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    .md-tab-indicator {
        background-color: #03a9f4 !important;
    }

    .member-search {
        position: relative;
        max-width: 500px;
        margin: 16px;
        left: 50%;
        transform: translateX(-50%);

        .member-search-results {
            position: absolute;
            left: 8px;
            top: 38px;
            background-color: #fff;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            padding: 8px;
            z-index: 10;
            max-width: 500px;
            width: 100%;
            height: 400px;
            overflow-y: scroll;
        
            box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
            -webkit-box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
        
            .member-container {
                width: calc(100% - 16px);
                display: flex;
                flex-grow: 0;
                align-items: center;
                padding: 16px;
                cursor: pointer;
                position: relative;
                text-decoration: none;
                color: inherit;
                flex-shrink: 0;
        
                border-bottom: 1px solid #e0e0e0;
        
                .member-profile {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    object-fit: cover;
                }
        
                .member-info {
                    margin: 0 16px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}