.push-settings {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;

    .push-headline {
        font-weight: bold;
        font-size: unset !important;
    }

    .push-subheadline {
        color: gray;
        margin-left: 8px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;
        margin-bottom: 16px;
    }
}