@import '~react-md/src/scss/react-md';

.mobile-identity {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    font-size: 24px;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;

    .picture-container {
        flex-basis: 0;
        flex-grow: 1;
        -webkit-flex-basis: 0;
        -webkit-flex-grow: 1;
        margin-right: 30px;

        .profilepicture {
            height: 136px;
            width: 136px;
            border-radius: 50%;
            object-fit: cover;
        }

        .picture-edit {
            cursor: pointer;
            position: absolute;
            top: 0px;
            right: 16px;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            font-size: 14px !important;
            display: flex !important;
            align-items: center;
            justify-content: center;
            background-color: white;
            color: $md-light-blue-500;
            box-shadow: 0 0 8px 1px #B8B8B8;
        }
    }

    .mobile-profile {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex-basis: 30px;
        flex-grow: 2;
        -webkit-flex-basis: 30px;
        -webkit-flex-grow: 2;

        .idtitle {
            color: gray;
            font-size: 14px;
            margin-top: 4px;
        }

        .idname {
            display: flex;
            align-items: center;

            .verified {
                margin-left: 8px;
            }
        }


        .idbio {
            color: #101010;
            font-size: 14px;
            margin-top: 4px;
            white-space: pre-wrap;
        }
    }
}

@media only screen and (max-width: 700px) {
    .mobile-identity {
        font-size: 20px !important;
    }

    .profilepicture {
        width: 96px !important;
        height: 96px !important;
    }
}