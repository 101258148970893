.ol-button {
    cursor: pointer;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background-color: var(--button-color);
    color: #ffffff;
    border-radius: 16px;
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    border: 1px solid var(--button-color);

    &:hover,
    &:focus {
        outline: 0;
        opacity: 0.6;
    }

    &:disabled {
        cursor: default;
        border: 1px solid rgba(lightgray, 0.2);
        background-color: rgba(lightgray, 0.2);
        color: lightgray;
    }
}