@import '../../variables.scss';

.toolbar,
.toolbar-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    position: fixed;
    top: 0;
    padding: 8px 16px;
    margin: 0 auto;
    background-color: #fff;
    z-index: 10;

    a {
        text-decoration: none;
    }

    .menu-logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        margin-right: auto;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .toolbar-tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        .toolbar-tab-wrapper {

            .toolbar-tab {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 4px;
                border-radius: 8px;
                cursor: pointer;
                font-size: 24px !important;

                &:hover {
                    background-color: rgba(153, 153, 153, 0.12);
                }

                .toolbar-notification {
                    background-color: #F4AC3D;
                    border-radius: 50%;
                    position: absolute;
                }
            }

            &.active {
                .toolbar-tab:hover {
                    background-color: transparent;
                }
            }
        }
    }

    .toolbar-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        margin-left: auto;

        .toolbar-action {
            display: flex;
            align-items: center;

            label {
                color: #101010;
                margin: 0 8px;
            }

            #language-label {
                display: none;
            }
        }

        #fullname-label {
            display: none;
            margin-left: 8px;
        }

        .profile-action {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            padding: 4px;
            margin: 0 8px;
            cursor: pointer;
            font-size: 14px;

            .profile-picture {
                border-radius: 50%;
                object-fit: cover !important;
            }

            &>* {
                cursor: pointer;
            }
        }

        .notifications-badge {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -16px;
            right: 0px;
            height: 16px;
            width: 16px;
            font-size: 10px;
            background-color: $fizlink-yellow;
            color: #101010;
            border-radius: 50%;
        }
    }
}

.toolbar {
    height: 64px;

    .toolbar-tabs {

        .toolbar-tab-wrapper {
            border-bottom: 2px solid transparent;

            .toolbar-tab {
                padding: 16px 32px;

                .toolbar-notification {
                    height: 6px;
                    width: 6px;
                    bottom: 8px;
                }
            }

            &.active {
                border-bottom: 2px solid rgb(3, 169, 244);
            }
        }
    }
}

.toolbar-mobile {
    height: 48px;

    .toolbar-tabs {

        .toolbar-tab-wrapper {

            .toolbar-tab {
                padding: 8px 16px;

                .toolbar-notification {
                    height: 4px;
                    width: 4px;
                    bottom: 2px;
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) {

    #language-label,
    #fullname-label {
        display: block !important;
    }

    #add-to-wallet {
        display: none;
    }
}