.valid-button {
    margin-left: 8px;
    height: 36px;
    width: 36px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: rgba(153, 153, 153, 0.12);
    }
}