@import '../../variables.scss';

.pagelet-button {
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.4s !important;
    
    &:not(div) {
        padding: 8px;
        border-radius: 50%;
    }

    &:hover {
        background-color: rgba(153, 153, 153, 0.12);
    }

    &.active {
        background-color: rgba($fizlink-blue, 0.20);
    }
}