:root {
  @import '~react-md/src/scss/react-md';
  @import "~@react-md/button/dist/styles";
  @import "~@react-md/dialog/dist/styles";
  @import "~@react-md/overlay/dist/styles";
  @import "~@react-md/list/dist/styles";
  @import "~@react-md/table/dist/styles";
  $md-primary-color: $md-light-blue-500;
  $md-secondary-color: $md-deep-orange-a-200;
  @include react-md-everything;

  @include react-md-theme-everything($md-primary-color,
    $md-secondary-color,
    false, // specifying dark-theme
    'dark-theme' // class name to wrap dark-theme styles in
  );
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.4;
  background-color: white;
}

html,
body,
#root {
  width: 100%;
  background-color: white !important;
}

a,
button {
  outline: none;

  ::-moz-focus-inner {
    border: 0;
  }
}

input {
  background-color: transparent;
}