.add-selector-overlay {
    position: fixed;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 30;
    animation: gradualBlur 0.5s linear;
    overflow: hidden;

    .links {
        width: 100%;
        max-height: calc(100% - 96px);
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        top: 0;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding: 16px;
        max-width: 700px;
        width: 100%;

        img.mobile-selector {
            cursor: pointer;
        }

        div.mobile-selector {
            label {
                color: #fafafa;
            }
        }
    }

    .add-selector-footer {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        bottom: 0;
        padding: 16px;
        overflow: hidden;

        div {
            cursor: pointer;
            background-color: #F4AC3D;
            border-radius: 50%;
            height: 64px;
            width: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: pop 0.4s linear 1;
            -webkit-animation: pop 0.4s linear 1;

            &:hover {
                opacity: 0.8;
            }

            i {
                color: #fafafa !important;
            }
        }
    }
}

@keyframes gradualBlur {

    0% {
        opacity: 0;
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);
    }

    25% {
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
    }

    50% {
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        opacity: 1;
    }

    75% {
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
    }

    100% {
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
}