.phone {
    position: relative;
    width: 360px;
    height: 722px;
    margin: auto;
    border: 16px black solid;
    border-radius: 36px;
    overflow: hidden;

    .phone-content {
        border-radius: 20px;
        width: calc(360px - 32px);
        height: calc(722px - 32px);
        background: white;
        position: relative;
    }
}