.WordSection1 {
  height: 100%;
  padding: 16px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  p.MsoNormal,
  li.MsoNormal,
  div.MsoNormal {
    margin: 0cm;
    margin-bottom: .0001pt;
    font-size: 12.0pt;
    font-family: "Times New Roman";
  }

  p {
    margin-right: 0cm;
    margin-left: 0cm;
    font-size: 12.0pt;
    font-family: "Times New Roman";
  }

  .MsoChpDefault {
    font-family: Calibri;
  }
}