.pagelet,
.pagelet-mobile {
    position: absolute;
    right: 16px;
    top: 68px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    z-index: 10;
    max-width: 300px;
    width: 100%;

    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);

    &.mobile {
        right: 8px;
        top: 54px;
    }
}