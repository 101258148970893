.email-settings {
    display: flex;
    flex-direction: column;
    margin: 16px 0;

    .email-headline {
        font-weight: bold;
        font-size: unset !important;
    }

    .email-subheadline {
        color: gray;
        margin-left: 12px;
        margin-top: -8px;
    }
}