.pagelet-item {
    padding: 8px;
    transition: all 0.2s;
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: rgba(153, 153, 153, 0.12);
    }

    .pagelet-icon {
        margin-right: 8px;
        padding: 8px;
        border-radius: 50%;
        background-color: #E4E6EB;
    }

    .pagelet-img {
        height: 36px;
        width: 36px;
        margin-right: 8px;
        border-radius: 50%;
        object-fit: cover;
    }

    .pagelet-display {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        text-overflow: ellipsis;
        word-break: break-word;

        .pagelet-title {
            font-weight: 500;
            font-size: 15px;
            color: #101010;
        }

        .pagelet-subheader {
            font-weight: 400;
            font-size: 14px;
            color: #65676B;
        }
    }

    &.profile {
        .pagelet-icon {
            height: 64px;
            width: 64px;
            object-fit: cover;
            margin-right: 16px;
            border-radius: 50%;
            padding: unset;
            background-color: unset;
        }

        .pagelet-title {
            font-size: 17px;
        }

        .pagelet-subheader {
            font-size: 15px;
        }
    }
}