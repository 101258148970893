@import '~react-md/src/scss/react-md';
@import '../../variables.scss';

.login {
    height: 100vh;
    background: #fff;
    display: flex;
    
    .image {
        width: 60%;
        margin: 16px;
        background-image: url(https://source.unsplash.com/random);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
    }
    
    .authentication {
        padding: 16px;
        height: calc(100vh - 16px);
        border: none !important;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .login-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                width: 90px;
            }

            a {
                text-decoration: none;
            }

            .signup-login {
                cursor: pointer;
                color: $md-grey-500;
                font-weight: bold;

                &:hover {
                    color: $md-light-blue-500;
                }
            }
        }

        .form {
            width: 100%;
            margin-top: 8px;

            .submit {
                width: 100%;
                margin: 16px 0px;
            }

            .signup-slogan {
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-family: "Arial Rounded MT Bold";
                    font-size: 24px;
                    margin-right: 4px;

                    &.free {
                        color: $fizlink-blue;
                    }
                }
            }

            .forgot-password {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: "Arial Rounded MT Bold";
                text-align: center;

                .header {
                    font-size: 24px;
                    margin-bottom: 16px;
                }

                .subheader {
                    font-size: 16px;
                }
            }

            .footer-container {
                display: flex;
                align-items: center;

                .forgot-password-link {
                    color: blue;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.thankyou {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .checkmark {
        width: 72px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        animation: popCheckmark 0.4s linear 1;
        -webkit-animation: popCheckmark 0.4s linear 1;
        margin: 16px;

        &>* {
            font-size: 36px !important;
        }
    }

    .thankyou-title {
        font-size: 34px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);
        margin: 8px;
    }
    
    .thankyou-subtitle {
        font-size: 20px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.87);
        margin: 8px;
    }
}

@keyframes popCheckmark {

    15% {
        background-color: rgba(0, 0, 0, 0.2);
    }

    25% {
        background-color: rgba(0, 0, 0, 0.2);
        transform: scale(0.5);
    }

    50% {
        transform: scale(1.2);
        opacity: 0.6;
    }

    100% {
    }
}
