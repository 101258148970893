.overview {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    
    .overview-grid {
        position: relative;
        width: 66%;
        max-height: calc(100% - 64px);
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .overview-preview {
        width: 34%;
        height: calc(100% - 16px);
        overflow-y: auto;
    }
}

@media only screen and (max-width: 600px) {

    .overview-grid {
        width: 100% !important;
    }

    .overview-preview {
        display: none;
    }
}
