$animation-delay: 0.4s;

.home {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    overflow-x: hidden;

    .home-header {
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        margin: 0 auto;
        padding: 16px;
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        animation: fadeAndTranslate 1.1s ease-out 1 forwards;
        -webkit-animation: fadeAndTranslate 1.1s ease-out 1 forwards;
        animation-delay: $animation-delay;
        -webkit-animation-delay: $animation-delay;
        display: flex;
        z-index: 99;

        &:after {
            position: absolute;
            display: block;
            content: '';
            top: 64px;
            left: 0;
            height: 1px;
            width: 0;
            animation: expandBorder 0.7s ease-out 1 forwards;
            -webkit-animation: expandBorder 0.7s ease-out 1 forwards;
            animation-delay: 1.8s;
            -webkit-animation-delay: 1.8s;
        }

        .signup-login {
            display: flex;
            align-items: center;

            .login-button {
                cursor: pointer;
                text-decoration: none;
                color: rgba(0, 0, 0, 0.54);
                font-size: 1rem;
                font-weight: bold;
                margin-right: 32px;

                &:hover {
                    color: #03a9f4;
                }
            }

            .toolbar-action {
                display: flex;
                align-items: center;
                margin: 0 8px;
    
                label {
                    color: #101010;
                    margin: 0 8px;
                }
    
                #language-label {
                    display: none;
                }
            }
    
            .profile-action {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                padding: 4px;
                margin: 0 8px;
                cursor: pointer;
                font-size: 14px;
    
                .profile-picture {
                    border-radius: 50%;
                    object-fit: cover !important;
                }
    
                &>* {
                    cursor: pointer;
                }
            }
        }
    }

    .home-content {
        overflow: scroll;
        position: absolute;
        top: 64px;
        width: 100%;
        height: calc(100% - 64px);
        text-align: center;
        -webkit-overflow-scrolling: touch;

        .page-wrapper {
            min-height: 66%;
            max-width: 700px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            overflow-y: hidden;

            &>* {
                opacity: 0;
                animation: fadeAndTranslate 1.1s ease-out 1 forwards;
                -webkit-animation: fadeAndTranslate 1.1s ease-out 1 forwards;
                animation-delay: $animation-delay;
                -webkit-animation-delay: $animation-delay;
            }
        }

        .home-title {
            font-size: 64px;
            color: rgba(#101010, 0.54);
            z-index: 10;
            animation: titleFade 1.1s ease-out 1 forwards;
            -webkit-animation: titleFade 1.1s ease-out 1 forwards;
        }

        .home-subheader {
            opacity: 0;
            color: rgba(#101010, 0.4);
            animation: fadeAndTranslate 1.1s ease-out 1 forwards;
            -webkit-animation: fadeAndTranslate 1.1s ease-out 1 forwards;
            animation-delay: $animation-delay;
            -webkit-animation-delay: $animation-delay;
        }

        .home-input {
            opacity: 0;
            animation: fadeAndTranslate 1.5s ease-out 1 forwards;
            -webkit-animation: fadeAndTranslate 1.5s ease-out 1 forwards;
            animation-delay: $animation-delay;
            -webkit-animation-delay: $animation-delay;
        }

        .split-page {
            display: flex;
            align-items: center;
            justify-items: center;
            width: 100%;
            height: 100%;

            h1 {
                font-size: 48px;
                line-height: 1.4;
            }

            &>div {
                width: 50%;
                margin: 16px;
                padding: 16px;
            }
        }

        .split-page-mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;

            h1 {
                font-size: 48px;
                line-height: 1.4;
            }

            &>div {
                width: 100%;
                margin: 16px;
                padding: 16px;
            }
        }
    }

    .home-footer {
        border-top: 1px solid #e0e0e0;
        padding: 16px;
        margin: 0 auto;
        max-width: 700px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@keyframes titleFade {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeAndTranslate {

    50% {
        opacity: 0;
        transform: translateY(32px);
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes expandBorder {
    0% {
        width: 0;
        background: transparent;
    }

    100% {
        width: 100%;
        background: #e0e0e0;
    }
}