.add-selector,
a.mobile-selector {
    position: relative;
    margin: 8px;
    width: calc(33% - 16px);
    display: flex;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    color: #101010;

    transition: all 0.4s;
    animation: pop 0.4s linear 1;
    -webkit-animation: pop 0.4s linear 1;

    border-radius: 16px;
    padding: 8px;

    &:hover {
        background-color: rgba(#e0e0e0, 0.4);
    }

    cursor: pointer;

    div.mobile-selector {
        position: relative;
    }

    img.mobile-selector {
        height: 96px;
        width: 96px;
        border-radius: 50%;
        object-fit: cover !important;
    }

    label {
        margin-top: 4px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        cursor: pointer;
    }

    .selector-privacy {
        cursor: pointer;
        position: absolute;
        bottom: 0px;
        right: 0px;
        border: 2px solid white;
        font-size: 14px !important;
        padding: 2px;
        border-radius: 50%;
        color: white;
        background-color: #00b16a;
    }
}

.highlight {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
}

a.mobile-selector-list {
    position: relative;
    margin: 8px;
    width: calc(100% - 16px);
    display: flex;
    align-items: center;

    color: #101010;
    text-decoration: none;

    border: 1px solid rgba(#e0e0e0, 0.6);

    transition: all 0.4s;
    -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    border-radius: 16px;
    padding: 8px;
    cursor: pointer;
    
    &:hover {
        border: 1px solid rgba(#e0e0e0, 0.4);
        background-color: rgba(#e0e0e0, 0.4);
    }

    div.mobile-selector-list {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(33% - 16px);
    }

    img.mobile-selector-list {
        height: 96px;
        width: 96px;
        border-radius: 50%;
        object-fit: cover !important;
    }

    div.mobile-caption-list {
        width: calc(66% - 16px);
        font-size: 1.1rem;

        padding: 0 8px;
        border-radius: 24px;
        background-color: transparent;

        color: #101010;
        text-decoration: none;

        label {
            cursor: pointer;
            overflow-x: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }

    .selector-privacy {
        cursor: pointer;
        position: absolute;
        bottom: 0px;
        right: 0px;
        border: 2px solid white;
        font-size: 14px !important;
        padding: 2px;
        border-radius: 50%;
        color: white;
        background-color: #00b16a;
    }
}

@media only screen and (max-width: 700px) {

    img.mobile-selector,
    img.mobile-selector-list {
        height: 72px !important;
        width: 72px !important;
    }
}

@keyframes pop {
    25% {
        transform: scale(0.5);
    }

    50% {
        transform: scale(1.2);
    }
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@-webkit-keyframes slide-left {
    0% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-left {
    0% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}