.onboarding {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 30;
    animation: gradualBlur 0.5s linear;
    touch-action: none;
    overflow: hidden;

    .onboarding-container {
        position: relative;
        width: 100%;
        max-width: 700px;
        left: 50%;
        transform: translateX(-50%);
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        height: calc(100% - 151px);

        .onboarding-image {
            padding: 16px;
            max-width: 100%;
            width: 100%;
            pointer-events: none;

            .picture-container {
                margin-right: 8px !important;
            }

            .mobile-profile {
                flex-basis: 8px !important;
                flex-grow: 2;
                -webkit-flex-basis: 8px !important;
                -webkit-flex-grow: 2;
            }

            .qr-contact-card {
                padding: 16px;
                position: relative;

                .qr-image {
                    position: absolute;
                    opacity: 0;
                    height: 30px;
                    width: 30px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    animation-timing-function: ease-in-out;
                    animation-iteration-count: infinite;
                    animation-name: qrShow;
                    animation-duration: 3s;

                    -webkit-animation-timing-function: ease-in-out;
                    -webkit-animation-iteration-count: infinite;
                    -webkit-animation-name: qrShow;
                    -webkit-animation-duration: 3s;
                }

                .phone {
                    display: inline-block;
                    width: 60px;
                    height: 120px;
                    border: 3px solid #fff;
                    border-radius: 8px;
                    position: relative;

                    animation-timing-function: ease-in-out;
                    animation-iteration-count: infinite;
                    animation-name: qrPhoneRotate;
                    animation-duration: 3s;

                    -webkit-animation-timing-function: ease-in-out;
                    -webkit-animation-iteration-count: infinite;
                    -webkit-animation-name: qrPhoneRotate;
                    -webkit-animation-duration: 3s;
                }
            }

            .onboarding-logo {
                animation: pop 0.4s linear 1;
                -webkit-animation: pop 0.4s linear 1;
            }

            .onboarding-profile-action {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px;
                cursor: pointer;
                font-size: 14px;
                background-color: white;
    
                .onboarding-profile-picture {
                    border-radius: 50%;
                    object-fit: cover !important;
                }
    
                &>* {
                    cursor: pointer;
                }
            }
        }

        .onboarding-title {
            font-size: 24px;
            font-weight: bold;
            color: white;
        }

        .onboarding-content {
            font-size: 18px;
            color: lightgray;
        }
    }

    .onboarding-stepper {
        position: relative;
        display: flex;
        width: 100%;
        padding: 16px;
        justify-content: center;

        .onboarding-step {
            cursor: pointer;
            width: 8px;
            height: 8px;
            margin-right: 8px;
            background-color: lightgray;
            border-radius: 50%;

            &.selected {
                background-color: #F4AC3D;
            }
        }
    }

    .onboarding-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .back-page,
        .next-page,
        .skip-page {
            margin: 8px;
            width: 100%;
            max-width: calc(700px - 64px);
        }

        .split-buttons {
            width: 100%;
            max-width: calc(700px - 64px);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .skip-page {
            color: white;
        }
    }

    #edit-link {
        animation: editLink 3s ease-out;
        -webkit-animation: editLink 3s ease-out;
    }

    #lock-link {
        opacity: 1;
        animation: lockLink 5s ease-out 3s;
        -webkit-animation: lockLink 5s ease-out 3s;
    }

    #unlock-link {
        opacity: 0;
        animation: unlockLink 5s ease-out 3s;
        -webkit-animation: unlockLink 5s ease-out 3s;
    }

    #delete-link {
        animation: deleteLink 3s ease-out 8s;
        -webkit-animation: deleteLink 3s ease-out 8s;
    }

    #reorder-link {
        animation: editLink 3s ease-out 11s;
        -webkit-animation: editLink 3s ease-out 11s;
    }
}

@keyframes qrPhoneRotate {

    0%,
    10% {
        transform: rotate(0deg)
    }

    40%,
    60% {
        transform: rotate(90deg)
    }

    95%,
    100% {
        transform: rotate(0deg)
    }
}

@keyframes qrShow {

    0%,
    20% {
        opacity: 0;
    }

    40%,
    60% {
        opacity: 1;
    }

    95%,
    100% {
        opacity: 0;
    }
}

@keyframes editLink {
    20% {
        fill: #F4AC3D;
    }

    40% {
        fill: #10101045;
    }

    60% {
        fill: #F4AC3D;
    }

    80% {
        fill: #10101045;
    }

    100% {
        fill: #F4AC3D;
    }
}

@keyframes lockLink {
    20% {

        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    60% {
        opacity: 0;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes unlockLink {
    20% {

        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes deleteLink {

    20% {
        fill: #EE3D4B;
    }

    40% {
        fill: #10101045;
    }

    60% {
        fill: #EE3D4B;
    }

    80% {
        fill: #10101045;
    }

    100% {
        fill: #EE3D4B;
    }
}