.mobile-toolbar {
    backdrop-filter: blur(30px);
    background-color: rgba(white, 0.6) !important;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    max-width: 700px;
    height: 64px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px;
    margin: 0 auto;
    background: #fff;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        text-decoration: none;
    }
}

.mobile-profile-container {
    width: 100%;
    position: relative;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.mobile-identity {

    .mobile-profile {

        .follow {
            background-color: #0095f6;
            color: white;
            border-radius: 4px;
            text-align: center;
            font-size: 16px;
            padding: 2px 32px;
            margin: 8px 0;
            cursor: pointer;

            &>* {
                cursor: pointer;
            }
        }
    }
}

.links {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mobile-collection {
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    margin: 0 auto;
    max-width: 700px;
    background-color: white;

    footer {
        position: absolute;
        width: 100%;
        max-width: 700px;
        height: 70px;
        bottom: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);
    }

    .create-your-own {
        font-size: 12px;
        color: #9e9e9e;
    }

    .create-your-own>a {
        color: #9e9e9e;
    }
}