.qr-profile {
    margin: 16px;
    height: calc(100vh - 32px);
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-y: scroll;

    .contact-info,
    .contact-qr {
        width: 50%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
            text-align: center;
        }
    }

    .contact-info {
        .profile-picture {
            width: 96px;
            height: 96px;
            border-radius: 50%;
            object-fit: cover !important;
        }

        span {
            margin: 16px;
        }

        font-size: 24px;
    }

    .contact-qr {
        .qr-code {
            height: calc(100% - 64px) !important;
            width: calc(100% - 64px) !important;
        }
    }
}