.tooltip {
    position: relative;
    display: inline-block;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    cursor: default;

    .tooltiptext {
        font-size: 12px;
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        text-align: center;
        padding: 4px 16px;
        border-radius: 6px;

        position: absolute;
        z-index: 1;

        &.top {
            bottom: calc(100% + 8px);
            left: 50%;
            transform: translate(-50%);

            &::after {
                content: " ";
                position: absolute;
                top: 100%;
    
                left: 50%;
                transform: translateX(-50%);
                border-width: 5px;
                border-style: solid;
                border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
            }
        }

        &.bottom {
            top: calc(100% + 8px);
            left: 50%;
            transform: translate(-50%);

            &::after {
                content: " ";
                position: absolute;
                bottom: 100%;
    
                left: 50%;
                transform: translateX(-50%);
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
            }
        }

        &.left {
            top: 50%;
            right: calc(100% + 8px);
            transform: translateY(-50%);

            &::after {
                content: " ";
                position: absolute;
                left: 100%;
    
                top: 50%;
                transform: translateY(-50%);
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
            }
        }

        &.right {
            top: 50%;
            left: calc(100% + 8px);
            transform: translateY(-50%);

            &::after {
                content: " ";
                position: absolute;
                right: 100%;
    
                top: 50%;
                transform: translateY(-50%);
                border-width: 5px;
                border-style: solid;
                border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
            }
        }
    }

    &:hover {
        .tooltiptext {
            visibility: visible;
        }
    }
}