.pending-follower {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    padding: 16px;
    border-radius: 8px;

    .pending-follower-profile {
        border-radius: 50%;
        background-size: cover !important;
    }
}

.follow-back {
    background-color: white;
    color: #101010;
    border: 1px solid #e0e0e0;
}

.approve-follower {
    background-color: #e3f2fd;
    color: #0d47a1;
    border: none;
}

.decline-follower {
    background-color: #ffebee;
    color: #b71c1c;
    border: none;
}

.approve-follower,
.decline-follower,
.follow-back {
    padding: 8px;
    margin: 8px 0;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
}