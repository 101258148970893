.fizlink-mobile-profile {
    .mobile-identity {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        text-align: left;
        width: 100%;
        font-size: 18px;
        padding: unset;
        padding-bottom: 16px;
    }

    .mobile-profile {
        flex-basis: unset !important;
        flex-grow: unset !important;
        -webkit-flex-basis: unset !important;
        -webkit-flex-grow: unset !important;
    }

    .picture-container {
        flex-basis: unset !important;
        flex-grow: unset !important;
        -webkit-flex-basis: unset !important;
        -webkit-flex-grow: unset !important;
        margin-right: unset !important;

        &:hover {
            opacity: 0.8;
        }

        .profilepicture {
            height: 72px !important;
            width: 72px !important;
            border-radius: 50%;
            margin-right: 16px !important;
            cursor: pointer;
            object-fit: cover;
        }
    }
}

.onboarding-profilepicture {
    width: 128px;
    height: 128px;
    object-fit: cover;
    border-radius: 50%;
    margin: 16px;
}

.profile-personalization {
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}