.contact-list {
    position: absolute;
    max-width: 700px;
    width: calc(100% - 32px);
    top: 64px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 16px auto;
    overflow: scroll;
    height: calc(100% - 96px);
    -webkit-overflow-scrolling: touch;

    .md-tab-indicator {
        background-color: #03a9f4 !important;
    }

    .paper {
        width: calc(100% - 16px);
        display: flex;
        flex-grow: 0;
        align-items: center;
        padding: 16px;
        cursor: pointer;
        position: relative;
        justify-content: space-between;
        text-decoration: none;
        color: inherit;

        .contact-container {
            display: flex;
            align-items: center;

            .contact-profile {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                object-fit: cover;
            }

            .contact-info {
                margin: 0 16px;
                display: flex;
                flex-direction: column;

                .verified {
                    margin-left: 4px;
                }
            }
        }

        .follow {
            background-color: #0095f6;
            color: white;
            border-radius: 4px;
            text-align: center;
            font-size: 14px;
            padding: 2px 32px;
            margin: 8px 0;
            cursor: pointer;
    
            &>* {
                cursor: pointer;
            }
        }

        .requested {
            border: 1px solid #e0e0e0;
            background-color: white;
            color: #101010;
            border-radius: 4px;
            text-align: center;
            font-size: 12px;
            padding: 2px 32px;
            margin: 8px 0;
            cursor: pointer;
        }

        .sponsored {
            display: flex;
            align-items: center;
            color: gray;
            font-size: 12px;
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

    .divider {
        margin: 16px auto;
        height: 1px;
        width: calc(100% - 16px);
        background-color: #e0e0e0;
    }
}