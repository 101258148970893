@import '../variables.scss';

.horizontal-loader {
    text-align: center;

    &>div {
        width: 14px;
        height: 14px;
        margin: 0 2px;
    
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
        animation: bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
        background: $fizlink-green;
        -webkit-animation-delay: -0.48s;
        animation-delay: -0.48s;
    }

    .bounce2 {
        background: $fizlink-blue;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .bounce3 {
        background: $fizlink-yellow;
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    .bounce4 {
        background: $fizlink-red;
    }
}

@-webkit-keyframes bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}