@import '../../variables.scss';

.profile-container {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    max-width: 900px;
    width: calc(100% - 32px);
    margin: 16px auto;
    padding: 16px;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    display: flex;

    .profile-options {
        border-right: 1px solid #e0e0e0;
        padding-right: 16px;
        margin-right: 16px;

    }

    .option-item,
    .option-item .selected {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 8px 16px;
        text-decoration: none;
        color: #101010;
        transition: 0.2s;
        border-radius: 8px;
        transition: all 0.2s;

        &:hover {
            background: #f1f3f4;
        }
    }

    .selected,
    .selected:hover {
        background: rgba($fizlink-blue, 0.5);
    }

    .profile-content {
        flex: 1;
    }

}